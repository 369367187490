import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import Login from './views/login/Login'
import Register from './views/register/Register'
import { AuthenticatedRoutes } from './routes/AuthenticatedRoutes'
import { BlockUI } from 'primereact/blockui'
import { Card } from 'primereact/card'
import { ProgressSpinner } from 'primereact/progressspinner'
import ForgotPassword from './views/forgotPassword/ForgotPassword'
import UpdatePassword from './views/updatePassword/updatePassword'
import ConfirmEmail from './views/confirmEmail/ConfirmEmail'
import ResendEmail from './views/resendEmail/ResendEmail'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

declare global {
  interface Window {
    blockUI: (block: boolean, message: string) => void
  }
}

function App() {
  const [blocked, setBlocked] = React.useState(false)
  const [message, setMessage] = React.useState('Wait, processing...')

  useEffect(() => {
    window.blockUI = blockUI
  }, [])

  const blockUI = (block: boolean, message?: string) => {
    setBlocked(block)
    if (message) {
      setMessage(message)
    } else {
      setMessage('Wait, processing...')
    }
  }

  return (
    <>
      <BlockUI
        blocked={blocked}
        template={
          <>
            <Card
              style={{
                textAlign: 'center',
                opacity: 0.95,
                width: '300px',
                height: '150px',
              }}
              header={
                <ProgressSpinner
                  className="overlay"
                  style={{ width: '32px', height: '32px', marginTop: '10px' }}
                  strokeWidth="6"
                  fill="#F8F9FA"
                  animationDuration="1.5s"
                ></ProgressSpinner>
              }
            >
              <h5>{message}</h5>
            </Card>
          </>
        }
        fullScreen
      />
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/resend-email" element={<ResendEmail />} />
            <Route element={<AuthenticatedRoutes />}>
              <Route path="*" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  )
}

export default App
