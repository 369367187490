import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import PyScript, {
  PyConfig,
  PyScriptProvider,
  PyTerminal,
} from 'pyscript-react'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import { PrimeReactProvider } from 'primereact/api'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <Provider store={store}>
    <PyScriptProvider>
      <PyConfig type="json" source="./config_all.json" />
      <PyScript source="./src/load_libs.py" />
      <PyScript source="./src/load_files.py" />
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
      <PyTerminal hidden={true} />
    </PyScriptProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
