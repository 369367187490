import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AuthService from '../../services/AuthService'

export function AuthenticatedRoutes() {
  const isAuthenticated = AuthService.isAuthenticated()

  return isAuthenticated ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace />
  )
}
