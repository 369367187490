import {
  IConfirmEmail,
  IRecoverPassword,
  IUserLogin,
  IUserSignUp,
} from '../interfaces/interfaces'
import { api } from '../lib/axios'

const signup = (user: IUserSignUp) => {
  return api.post('/users', user)
}

const login = (user: IUserLogin) => {
  return api.post('/login', user)
}

const forgotPassword = (email: string) => {
  return api.post(`/users/send-code-recover-password/email/${email}`)
}

const recoverPassword = (recoverPassword: IRecoverPassword) => {
  return api.post(`/users/reset-password`, recoverPassword)
}

const confirmEmail = (confirmEmail: IConfirmEmail) => {
  return api.post(`/users/confirm-email`, confirmEmail)
}

const resendEmail = (confirmEmail: IConfirmEmail) => {
  return api.post(`/users/resend-email`, confirmEmail)
}

const isAuthenticated = () => {
  const token = localStorage.getItem('@sec-auspex-tk')
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(token)}`
  }

  return token ? true : false
}

const logout = () => {
  localStorage.removeItem('@sec-auspex-tk')
}

const AuthService = {
  signup,
  login,
  isAuthenticated,
  logout,
  forgotPassword,
  recoverPassword,
  confirmEmail,
  resendEmail,
}

export default AuthService
