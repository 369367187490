import React, { useRef, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import { useForm, SubmitHandler } from 'react-hook-form'
import AuthService from '../../services/AuthService'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { IConfirmEmail } from '../../interfaces/interfaces'

const ResendEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IConfirmEmail>()
  const toast = useRef(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [pendingApiCall, setPendingApiCall] = useState(false)
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<IConfirmEmail> = (data) => {
    setPendingApiCall(true)
    window.blockUI(true, 'Requesting verification email...')
    const confirmEmail: IConfirmEmail = {
      email: data.email,
    }
    AuthService.resendEmail(confirmEmail)
      .then((response) => {
        window.blockUI(false, 'An email has been sent to your inbox.')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'An email has been sent to confirm your account.',
          life: 10000,
        })
        window.setTimeout(() => {
          navigate('/login')
        }, 10000)
      })
      .catch((errorResponse) => {
        window.blockUI(false, 'Error sending email. Please, try again.')
        setErrorMessage(errorResponse.response.data.message)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'error',
          summary: 'Error sending email',
          detail: 'Verify your email address. Please try again.',
          life: 3000,
        })
        setPendingApiCall(false)
      })
  }
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <Toast ref={toast} position="top-center" />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h1>Resend confirmation email</h1>
                  <p className="text-body-secondary">
                    please enter your email address
                  </p>
                  <div className="mb-2">
                    <label
                      className={errors.email && 'p-error'}
                      htmlFor="email"
                    >
                      Email*
                    </label>
                    <CInputGroup>
                      <CInputGroupText>@</CInputGroupText>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        {...register('email', {
                          required: 'Email is required.',
                        })}
                      />
                    </CInputGroup>
                    {errors.email && (
                      <small className="p-error">{errors.email.message}</small>
                    )}
                    {errorMessage && (
                      <small className="p-error">{errorMessage}</small>
                    )}
                  </div>
                  <div className="d-grid">
                    <Button
                      disabled={pendingApiCall}
                      label="Send email"
                      type="submit"
                      severity="success"
                    ></Button>
                  </div>
                </form>
                <CCardBody className="p-1">
                  <div className="text-center">
                    <CButton color="link" className="px-0">
                      <Link to={'/login'}>Login page</Link>
                    </CButton>
                    <span> or </span>
                    <CButton color="link" className="px-0">
                      <Link to={'/register'}>Create account</Link>
                    </CButton>
                  </div>
                </CCardBody>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResendEmail
