import React, { useEffect, useRef, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'

import { IRecoverPassword } from '../../interfaces/interfaces'
import { useForm, SubmitHandler } from 'react-hook-form'

import AuthService from '../../services/AuthService'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

const UpdatePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IRecoverPassword>()
  const navigate = useNavigate()
  const toast = useRef(null)
  const [validationErrors, setValidationErrors] = useState({
    code: '',
    password: '',
    repeatPassword: '',
  })
  const [pendingApiCall, setPendingApiCall] = useState(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    console.log('test')
  }, [])

  const onSubmit: SubmitHandler<IRecoverPassword> = (data) => {
    setPendingApiCall(true)
    window.blockUI(true, 'Updating password...')
    const recoverPassword: IRecoverPassword = {
      code: searchParams.get('code') || '',
      password: data.password,
      repeatPassword: data.repeatPassword,
    }
    AuthService.recoverPassword(recoverPassword)
      .then((response) => {
        window.blockUI(false, 'Password updated...')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail:
            'Your password has been updated. You will be redirected to the login page.',
          life: 1000,
        })
        window.setTimeout(() => {
          setPendingApiCall(false)
          navigate('/login')
        }, 1000)
      })
      .catch((errorResponse) => {
        window.blockUI(false, 'Error updating. Please, try again.')
        if (errorResponse.response.data.validationErrors) {
          setValidationErrors(errorResponse.response.data.validationErrors)
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'error',
          summary: 'Error creating account',
          detail: 'Verify your data. Please try again.',
          life: 3000,
        })
        setPendingApiCall(false)
      })
  }
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <Toast ref={toast} position="top-center" />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h1>Update your password</h1>
                  <p className="text-body-secondary">
                    to continue using WebAUSPEX
                  </p>
                  <div className="mb-2">
                    <label
                      className={errors.password ? 'p-error' : ''}
                      htmlFor="password"
                    >
                      Password*
                    </label>
                    <CInputGroup>
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        {...register('password', {
                          required: 'Password is required.',
                          minLength: {
                            value: 6,
                            message:
                              'Password must be at least 6 characters long.',
                          },
                          maxLength: {
                            value: 20,
                            message:
                              'Password must be at most 20 characters long.',
                          },
                        })}
                      />
                    </CInputGroup>
                    {errors.password && (
                      <small className="p-error">
                        {errors.password.message}
                      </small>
                    )}
                    {validationErrors.password && (
                      <small className="p-error">
                        {validationErrors.password}
                      </small>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      className={errors.repeatPassword ? 'p-error' : ''}
                      htmlFor="repeatPassword"
                    >
                      Password*
                    </label>
                    <CInputGroup>
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Repeat password"
                        {...register('repeatPassword', {
                          required: 'Repeat password is required.',
                          minLength: {
                            value: 6,
                            message:
                              'Repeat password must be at least 6 characters long.',
                          },
                          maxLength: {
                            value: 20,
                            message:
                              'Repeat password must be at most 20 characters long.',
                          },
                          validate: (val: string) => {
                            if (watch('password') !== val) {
                              return 'Your passwords do no match'
                            }
                          },
                        })}
                      />
                    </CInputGroup>
                    {errors.repeatPassword && (
                      <small className="p-error">
                        {errors.repeatPassword.message}
                      </small>
                    )}
                    {validationErrors.repeatPassword && (
                      <small className="p-error">
                        {validationErrors.repeatPassword}
                      </small>
                    )}
                  </div>
                  <div className="d-grid">
                    {validationErrors.code && (
                      <div className="mb-4">
                        <small className="p-error">
                          {validationErrors.code}
                        </small>
                      </div>
                    )}
                    <Button
                      disabled={pendingApiCall}
                      label="Update password"
                      type="submit"
                      severity="success"
                    ></Button>
                  </div>
                </form>
                <CCardBody className="p-1">
                  <div className="text-center">
                    <CButton color="link" className="px-0">
                      <Link to={'/login'}>Login page</Link>
                    </CButton>
                  </div>
                </CCardBody>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default UpdatePassword
