import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilAt, cilLockLocked } from '@coreui/icons'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IUserLogin } from '../../interfaces/interfaces'
import AuthService from '../../services/AuthService'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import logo from '../../assets/brand/LASSIP.png'

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserLogin>()

  const [pendingApiCall, setPendingApiCall] = useState(false)
  const navigate = useNavigate()
  const toast = useRef(null)

  const onSubmit: SubmitHandler<IUserLogin> = (data) => {
    setPendingApiCall(true)
    window.blockUI(true, 'Logging in...')
    const userLogin: IUserLogin = {
      email: data.email,
      password: data.password,
    }
    AuthService.login(userLogin)
      .then((response) => {
        localStorage.setItem(
          '@sec-auspex-tk',
          JSON.stringify(response.data.token),
        )

        window.blockUI(false, 'Login successful')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'success',
          summary: 'Login successful',
          detail: 'You are now logged in.',
          life: 1000,
        })
        window.setTimeout(() => {
          setPendingApiCall(false)
          navigate('/')
        }, 1000)
      })
      .catch((errorResponse) => {
        setPendingApiCall(false)
        window.blockUI(false, 'Login failed')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'error',
          summary: 'Login failed',
          detail: 'Wrong email or password. Please try again.',
          life: 3000,
        })
        console.error(errorResponse)
      })
  }

  return (
    <>
      <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
        <Toast ref={toast} position="top-center" />
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <div className="bg-body-primary text-center">
                    <a
                      href="https://utfpr.curitiba.br/lassip/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={String(logo)} alt="WebAUSPEX" height={64} />
                    </a>
                  </div>
                  <CCardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <h1>Sign in</h1>
                      <p className="text-body-secondary">
                        to continue to WebAUSPEX
                      </p>
                      <div className="mb-3">
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilAt} />
                          </CInputGroupText>
                          {/* <CFormInput
                        placeholder="E-mail"
                        autoComplete="username"
                      /> */}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="email@example.com"
                            {...register('email', {
                              required: 'Email is required.',
                            })}
                          />
                        </CInputGroup>
                        {errors.email && (
                          <small className="p-error">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                      <div className="mb-4">
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          {/* <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      /> */}
                          <input
                            type="password"
                            className="form-control"
                            placeholder="********"
                            {...register('password', {
                              required: 'Password is required.',
                            })}
                          />
                        </CInputGroup>
                        {errors.password && (
                          <small className="p-error">
                            {errors.password.message}
                          </small>
                        )}
                      </div>
                      <CRow>
                        <CCol xs={6}>
                          {/* <CButton color="primary" className="px-4">
                          Login
                        </CButton> */}
                          <Button
                            disabled={pendingApiCall}
                            label="Login"
                            type="submit"
                            severity="info"
                          ></Button>
                        </CCol>
                        <CCol xs={6} className="text-right">
                          <Link to={'/forgot-password'}>Forgot password?</Link>
                        </CCol>
                        <CCol xs={12} className="text-right">
                          <Link to={'/resend-email'}>
                            Resend confirmation email
                          </Link>
                        </CCol>
                      </CRow>
                    </form>
                  </CCardBody>
                </CCard>
                <CCard
                  className="text-white bg-primary py-5"
                  style={{ width: '44%' }}
                >
                  <CCardBody className="text-center">
                    <div>
                      <h2>WebAUSPEX - Sign up</h2>
                      <p>
                        WebAUSPEX is a toolbox for ultrasound signal processing
                        and image reconstruction whose main goal is the
                        detection and mapping of internal corrosion in
                        underwater oil pipelines.
                      </p>
                      <p>Create your account to continue to WebAUSPEX.</p>
                      <Link to="/register">
                        <CButton
                          color="primary"
                          className="mt-3"
                          active
                          tabIndex={-1}
                        >
                          Create Account
                        </CButton>
                      </Link>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default Login
