import React, { useEffect, useRef, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  cilHome,
  cilLibraryBuilding,
  cilLockLocked,
  cilUser,
} from '@coreui/icons'

import { Country, IUserSignUp } from '../../interfaces/interfaces'
import { CountryService } from '../../services/CountryService'
import { useForm, SubmitHandler } from 'react-hook-form'

import './flags.css'
import './style.css'
import AuthService from '../../services/AuthService'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'

const Register = () => {
  const [countries, setCountries] = useState<Country[]>([])
  const [selectedCountry, setSelectedCountry] = useState<Country>()
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IUserSignUp>()
  const navigate = useNavigate()
  const toast = useRef(null)
  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    institution: '',
    countryAlphaTwoCode: '',
    password: '',
    repeatPassword: '',
  })
  const [pendingApiCall, setPendingApiCall] = useState(false)
  const [accountCreated, setAccountCreated] = useState(false)

  useEffect(() => {
    CountryService.getCountries().then((data) => setCountries(data))
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedCountryTemplate = (option: Country, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="assets/images/flags/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: '18px' }}
          />
          <div>{option.name}</div>
        </div>
      )
    }

    // eslint-disable-next-line react/prop-types
    return <span>{props.placeholder}</span>
  }

  const countryOptionTemplate = (option: Country) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="assets/images/flags/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: '18px' }}
        />
        <div>{option.name}</div>
      </div>
    )
  }

  const onSubmit: SubmitHandler<IUserSignUp> = (data) => {
    setPendingApiCall(true)
    window.blockUI(true, 'Creating account...')
    const userSignUp: IUserSignUp = {
      name: data.name,
      email: data.email,
      institution: data.institution,
      countryAlphaTwoCode: data.countryAlphaTwoCode,
      password: data.password,
      repeatPassword: data.repeatPassword,
    }
    AuthService.signup(userSignUp)
      .then((response) => {
        window.blockUI(false, 'Account created...')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail:
            'Your account has been created. Please check your email to verify your account.',
          life: 1000,
        })
        setAccountCreated(true)
        window.setTimeout(() => {
          setPendingApiCall(false)
          navigate('/login')
        }, 10000)
      })
      .catch((errorResponse) => {
        window.blockUI(false, 'Error creating accound. Please, try again.')
        setValidationErrors(errorResponse.response.data.validationErrors)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'error',
          summary: 'Error creating account',
          detail: 'Verify your data. Please try again.',
          life: 3000,
        })
        setPendingApiCall(false)
      })
  }
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <Toast ref={toast} position="top-center" />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {!accountCreated && (
                  <>
                    <h1>Create your account</h1>
                    <p className="text-body-secondary">
                      to continue to WebAUSPEX
                    </p>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-2">
                        <label
                          className={errors.name && 'p-error'}
                          htmlFor="name"
                        >
                          Full name*
                        </label>
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          {/* <CFormInput placeholder="Full name" autoComplete="name" /> */}
                          <input
                            autoComplete="nope"
                            className="form-control"
                            type="text"
                            placeholder="Full name"
                            {...register('name', {
                              required: 'Full name is required.',
                            })}
                          />
                        </CInputGroup>
                        {errors.name && (
                          <small className="p-error">
                            {errors.name.message}
                          </small>
                        )}
                        {validationErrors.name && (
                          <small className="p-error">
                            {validationErrors.name}
                          </small>
                        )}
                      </div>
                      <div className="mb-2">
                        <label
                          className={errors.email && 'p-error'}
                          htmlFor="email"
                        >
                          Email*
                        </label>
                        <CInputGroup>
                          <CInputGroupText>@</CInputGroupText>
                          <input
                            autoComplete="new-password"
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            {...register('email', {
                              required: 'Email is required.',
                            })}
                          />
                        </CInputGroup>
                        {errors.email && (
                          <small className="p-error">
                            {errors.email.message}
                          </small>
                        )}
                        {validationErrors.email && (
                          <small className="p-error">
                            {validationErrors.email}
                          </small>
                        )}
                      </div>
                      <div className="mb-2">
                        {/* <CFormLabel htmlFor="institution">Institution</CFormLabel> */}
                        <label
                          className={errors.institution && 'p-error'}
                          htmlFor="institution"
                        >
                          Institution*
                        </label>
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilLibraryBuilding} />
                          </CInputGroupText>
                          {/* <CFormInput
                        placeholder="Institution"
                        autoComplete="institution"
                      /> */}
                          <input
                            autoComplete="nope"
                            className="form-control"
                            type="text"
                            placeholder="Institution"
                            {...register('institution', {
                              required: 'Institution is required.',
                            })}
                          />
                        </CInputGroup>
                        {errors.institution && (
                          <small className="p-error">
                            {errors.institution.message}
                          </small>
                        )}
                        {validationErrors.institution && (
                          <small className="p-error">
                            {validationErrors.institution}
                          </small>
                        )}
                      </div>
                      <div className="mb-2">
                        <label
                          className={
                            errors.countryAlphaTwoCode ? 'p-error' : ''
                          }
                          htmlFor="country"
                        >
                          Country*
                        </label>
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilHome} />
                          </CInputGroupText>
                          {/* <CFormInput placeholder="Country" autoComplete="country" /> */}
                          <Dropdown
                            autoComplete="nope"
                            style={{
                              width: '92%',
                              border: '1px solid #ced4da',
                            }}
                            value={selectedCountry}
                            onChange={(e) => {
                              setSelectedCountry(e.value)
                              setValue('countryAlphaTwoCode', e.value?.code)
                              errors.countryAlphaTwoCode = undefined
                            }}
                            options={countries}
                            optionLabel="name"
                            placeholder="Select a Country"
                            filter
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                          />

                          <input
                            type="hidden"
                            {...register('countryAlphaTwoCode', {
                              required:
                                'Country is required. Select one of the list.',
                            })}
                          />
                        </CInputGroup>
                        {errors.countryAlphaTwoCode && (
                          <small className="p-error">
                            Country is required.
                          </small>
                        )}
                        {validationErrors.countryAlphaTwoCode && (
                          <small className="p-error">
                            {validationErrors.countryAlphaTwoCode}
                          </small>
                        )}
                      </div>
                      <div className="mb-2">
                        <label
                          className={errors.password ? 'p-error' : ''}
                          htmlFor="password"
                        >
                          Password*
                        </label>
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          {/* <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                      /> */}
                          <input
                            autoComplete="new-password"
                            className="form-control"
                            type="password"
                            placeholder="Password"
                            {...register('password', {
                              required: 'Password is required.',
                              minLength: {
                                value: 8,
                                message:
                                  'Password must be at least 8 characters long.',
                              },
                              maxLength: {
                                value: 20,
                                message:
                                  'Password must be at most 20 characters long.',
                              },
                              validate: (value: string) => {
                                return (
                                  [/[a-z]/, /[A-Z]/, /[0-9]/].every((pattern) =>
                                    pattern.test(value),
                                  ) ||
                                  'Password must contain at least one uppercase letter, one lowercase letter and one number.'
                                )
                              },
                            })}
                          />
                        </CInputGroup>
                        {errors.password && (
                          <small className="p-error">
                            {errors.password.message}
                          </small>
                        )}
                        {validationErrors.password && (
                          <small className="p-error">
                            {validationErrors.password}
                          </small>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          className={errors.repeatPassword ? 'p-error' : ''}
                          htmlFor="repeatPassword"
                        >
                          Repeat Password*
                        </label>
                        <CInputGroup>
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          {/* <CFormInput
                        type="password"
                        placeholder="Repeat password"
                        autoComplete="repeat-password"
                      /> */}
                          <input
                            autoComplete="nope"
                            className="form-control"
                            type="password"
                            placeholder="Repeat password"
                            {...register('repeatPassword', {
                              required: 'Repeat password is required.',
                              minLength: {
                                value: 8,
                                message:
                                  'Repeat password must be at least 8 characters long.',
                              },
                              maxLength: {
                                value: 20,
                                message:
                                  'Repeat password must be at most 20 characters long.',
                              },
                              validate: (val: string) => {
                                if (watch('password') !== val) {
                                  return 'Your passwords do no match'
                                }
                              },
                            })}
                          />
                        </CInputGroup>
                        {errors.repeatPassword && (
                          <small className="p-error">
                            {errors.repeatPassword.message}
                          </small>
                        )}
                        {validationErrors.repeatPassword && (
                          <small className="p-error">
                            {validationErrors.repeatPassword}
                          </small>
                        )}
                      </div>
                      <div className="d-grid">
                        <Button
                          disabled={pendingApiCall}
                          label="Create Account"
                          type="submit"
                          severity="success"
                        ></Button>
                      </div>
                    </form>
                  </>
                )}
                {accountCreated && (
                  <>
                    <h1>WebAUSPEX account</h1>
                    <p className="text-body-secondary">successfully created!</p>
                    <div className="text-center">
                      <div className="alert alert-success">
                        Your account has been created. Please check your email
                        to verify your account.
                      </div>
                      <CButton color="link" className="px-0">
                        <Link to={'/login'}> Login page</Link>
                      </CButton>
                    </div>
                  </>
                )}
                {!accountCreated && (
                  <CCardBody className="p-1">
                    <div className="text-center">
                      <CButton color="link" className="px-0">
                        <Link to={'/login'}>
                          {' '}
                          Already have an account? Login
                        </Link>
                      </CButton>
                    </div>
                  </CCardBody>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Register
