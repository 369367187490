import React, { useEffect, useRef, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react-pro'

import { IConfirmEmail, IRecoverPassword } from '../../interfaces/interfaces'
import { useForm, SubmitHandler } from 'react-hook-form'

import AuthService from '../../services/AuthService'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

const ConfirmEmail = () => {
  const { handleSubmit } = useForm<IRecoverPassword>()
  const navigate = useNavigate()
  const toast = useRef(null)
  const [pendingApiCall, setPendingApiCall] = useState(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    console.log('test')
  }, [])

  const onSubmit: SubmitHandler<IConfirmEmail> = (data) => {
    setPendingApiCall(true)
    window.blockUI(true, 'Updating password...')
    const recoverPassword: IConfirmEmail = {
      code: searchParams.get('code') || '',
    }
    AuthService.confirmEmail(recoverPassword)
      .then((response) => {
        window.blockUI(false, 'Email confirmed...')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail:
            'Your email has been confirmed. You will be redirected to the login page.',
          life: 2000,
        })
        window.setTimeout(() => {
          setPendingApiCall(false)
          navigate('/login')
        }, 1000)
      })
      .catch((errorResponse) => {
        window.blockUI(false, 'Error confirming your email. Please, try again.')

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        toast.current?.show({
          severity: 'error',
          summary: 'Error confirming your email',
          detail: 'Error confirming your email. Please try again.',
          life: 3000,
        })
        setPendingApiCall(false)
      })
  }
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <Toast ref={toast} position="top-center" />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h1>Confirm your email</h1>
                  <p className="text-body-secondary">
                    to start using WebAUSPEX
                  </p>
                  <div className="mb-2">
                    <Button
                      disabled={pendingApiCall}
                      label="Click here to confirm your email"
                      type="submit"
                      severity="success"
                    ></Button>
                  </div>
                </form>
                <CCardBody className="p-1">
                  <div className="text-center">
                    <CButton color="link" className="px-0">
                      <Link to={'/login'}>Login page</Link>
                    </CButton>
                  </div>
                </CCardBody>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ConfirmEmail
